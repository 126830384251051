function IsPC() {
  var userAgentInfo = navigator.userAgent
  var Agents = new Array(
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod'
  )
  var flag = true
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
}

$(function() {
  if (!IsPC()) {
    $('.sec02 .video_button').show()
    // $('.fixImg').css({ height: '200px', 'margin-top': '50%' })
  }
  $.fn.fullpage({
    anchors: [
      'page1',
      'page2',
      'page3',
      'page4',
      'page5',
      'page6',
      'page7',
      'page9',
      'page8'
    ],
    scrollHorizontally: true,
    menu: '#menu',
    // css3: true,
    afterLoad: function(anchorLink, index) {
      setTarget()

      if (index == 1) {
        animSvg()
        // $('.sec01').removeClass('leave')
      }
      if (index == 2) {
        if (IsPC()) {
          if ($('.sec02 video').length) {
            if ($('.sec02 video')[0].paused) {
              $('.sec02 video')[0].play()
              $('.sec02 video')[0].muted = false
              $('.sound').addClass('sounding')
            }
          } else {
            $('.sec02 .fixImg').vidbg(
              {
                // mp4: 'https://assets.hervillageclub.com/68a04b77.m4v',
                mp4: 'https://assets.hervillageclub.com/d71e1abe.mp4',
                poster: '/dist/images/sec02_bg.png'
              },
              {
                // 参数选项
                loop: true,
                overlay: true
              }
            )

            $('.sec02').append("<div class='sound'></div>")
            $('.sound').on('click', function() {
              if (!$('.sound').hasClass('sounding')) {
                $('.sound').addClass('sounding')
                $('.sec02 video')[0].muted = false
              } else {
                $('.sound').removeClass('sounding')
                $('.sec02 video')[0].muted = true
              }
            })
          }
        } else {
          video1 = document.getElementById('video1') //获取视频节点
          if (video1) {
            if (video1.paused) {
              video1.play()
            }
          }
        }
      }
      if (index == 3) {
        video = document.getElementById('video') //获取视频节点
        if (video) {
          if (video.paused) {
            video.play()
          }
        }
      }
    },
    onLeave: function(index, direction) {
      setTarget()
      if (index == '1') {
        // $('.sec01').addClass('leave')
      }
      if (index == '3') {
        video = document.getElementById('video') //获取视频节点
        if (video) {
          video.pause()
        }
      }
      if (index == '2') {
        if (!IsPC()) {
          video1 = document.getElementById('video1') //获取视频节点
          if (video1) {
            video1.pause()
          }
        } else {
          $('.sound').removeClass('sounding')
          $('.sec02 video')[0].pause()
          $('.sec02 video')[0].muted = true
        }
      }
    }
  })

  $(window).load(function() {
    setTimeout(setTarget, isSp ? 0 : 800)
    setTimeout(
      function() {
        animSvg()
        $('.sec03 .video_button').on('click', function() {
          $('.sec03 .video_button').hide()
          $('.sec03 img').css({ visibility: 'hidden' })
          // $('.sec03 .bigPhoto').vidbg(
          //   {
          //     mp4: 'https://assets.hervillageclub.com/68a04b77.m4v'
          //     // m3u8: 'https://assets.hervillageclub.com/videos/68a04b77.m3u8'
          //     // poster: 'http://hervillageclub.com/dist/images/yanglan.jpg'
          //   },
          //   {
          //     // 参数选项
          //     muted: false,
          //     loop: true,
          //     overlay: false
          //   }
          // )
          $('.sec03 .bigPhoto').html(
            '<span class="title">YangLan</span><video x5-playsinline="" playsinline="" webkit-playsinline="" id="video" style=\'width: 100%\' src=\'https://assets.hervillageclub.com/68a04b77.m4v\' preload="auto" controlslist="nodownload" controls="false" autoplay="autoplay"></video>'
          )
          video = document.getElementById('video') //获取视频节点
        })
      },
      isSp ? 500 : 1000
    )
  })

  $('.footBottom .contact').on(IsPC() ? 'mouseenter' : 'click', function() {
    console.log(IsPC() ? 'mouseenter' : 'click')
    $('.footBottom .contact .tip').hide()
    $(this)
      .find('.tip')
      .show()
    $(this)
      .unbind('mouseleave')
      .on('mouseleave', function() {
        $('.footBottom .contact .tip').hide()
      })
  })

  $('.footBottom .tip div').on('mouseenter click', function() {
    $('.footBottom .contact .tip').hide()
  })
})

$('.sec02 .video_button').on('click', function() {
  $('.sec02 .video_button').hide()
  $('.sec02 .video').show()
  $('.sec02 img').css({ visibility: 'hidden' })

  $('.sec02 .fixImg').html(
    '<video x5-playsinline="" playsinline="" webkit-playsinline="" id="video1" style=\'margin-top:50%; width: 100%\' src=\'https://assets.hervillageclub.com/d71e1abe.mp4\' preload="auto" controlslist="nodownload" controls="false" autoplay="autoplay"></video>'
  )
})

function animSvg() {
  $('.sec01 h2').css({ opacity: 1, visibility: 'visible' })
  new Vivus('svg-animation', {
    type: 'scenario-sync',
    duration: 12,
    forceRender: false,
    animTimingFunction: Vivus.EASE
  })
}

function setTarget() {
  var fadeObj = $('.target')
  if (!fadeObj.length) return
  var delayH = isSp ? 150 : 300

  fadeObj.each(function() {
    var elm = $(this),
      elmT = elm.offset().top,
      elmH = elm.height()

    if (winST > elmT - winH + delayH && winST < elmT + elmH) {
      elm.addClass('visible')
    } else if (winST < elmT - winH + delayH && winST < elmT + delayH) {
      elm.removeClass('visible')
    }
  })
}
